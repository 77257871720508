import { Injectable } from '@angular/core';
import { CountryCode } from 'src/app/models';
import { RegionService } from '../region/region.service';

@Injectable({
  providedIn: 'root'
})
export class FacebookPixelService {

  facebookPixelIdMap: Map<CountryCode, string> = new Map<CountryCode, string>();

  constructor(private regionService: RegionService) { 
    this.facebookPixelIdMap.set(CountryCode.NO, '1752609402164920');
    this.facebookPixelIdMap.set(CountryCode.SE, '1057896036075085');
   
  }

  public async getFacebookPixelId(): Promise<string | undefined> {
    return this.facebookPixelIdMap.get(this.regionService.getClientRegion());
  }

  
}

